<template>
  <div>
    <p v-if="showNomore" class="nomore">暂无数据</p>
    <notice-list :set="false" :datalist="listData"></notice-list>
    <el-pagination
      background
      @current-change="handleCurrentChange"
      layout="prev, pager, next"
      :page-size.sync="pageCount"
      :current-page="currentPage"
      :total="total"
      v-if="total>pageCount"
    ></el-pagination>
  </div>
</template>
<script>
import { noticeListRequest} from "@/api/project.js";
import NoticeList from "../components/noticelist.vue";
export default {
  components: {
    NoticeList
  },
  data() {
    return {
      listData:[],
      currentPage:1,
      total:2,
      pageCount:10,
      showNomore:false
    };
  },
  created() {
    this.getList();
  },
  methods: {
    async getList(val) {
      try {
        val=val || '1'
        const result = await noticeListRequest({
          type:1,
          page: val
        });
        if (result.status == 200) {
          this.listData = result.data.list;
          this.total=result.data.totalCount;
          if(result.data.totalCount==0)this.showNomore=true
          this.pageCount=result.data.pageCount
        }
      } catch (err) {}
    },
    handleCurrentChange(val) {
      this.getList(val);
    }
  }
};
</script>
<style  lang="less" scoped>
.el-pagination{
text-align: right;
margin:20px;
}
.nomore{
  height:150px;
  line-height: 150px;
  text-align: center;
  font-size: 14px;
  color: #999;
}
</style>